import * as React from 'react';
import { Outlet, Link } from 'react-router-dom';
import Header from './header/header';
import Sidebar from './sidebar/sidebar';


class Layout extends React.Component {
    render(){
      return (  
        <>
        <Header />
        <Sidebar/>
        <footer><p>Powered by <a href='https://www.4seeanalytics.com/' target="_blank"><img src="assets/images/logo.svg" alt="4see analytics" width="40"/></a></p></footer>
        <div class="page-loader" id="global-spinner">      
        
        <div class="loader" id="loader-1"></div>
        <div class="txt"><img src="assets/images/logo.svg" width="150"/></div>
        </div>
        </>
      )
    }
  }
  export default Layout;

